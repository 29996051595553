<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Businesses Overview">
      <template v-slot:actions>
        <q-btn
          label="New Business"
          to="/manage/businesses/create"
          color="primary"
        />
      </template>
    </PageHeader>

    <DataTable
      table-key="businessesOverview"
      row-key="id"
      title="Businesses"
      :columns="columns"
      :rows="businesses"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                $router.push({
                  path: '/manage/businesses/edit/' + row.id
                }),
              label: 'Edit'
            },
            {
              action: () => promptDeleteBusiness(row),
              label: 'Delete'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import { Dialog } from "quasar";
import moment from "moment";
import DataTable from "@/components/DataTable";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
moment.locale("en");

export default {
  name: "BusinessesOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          name: "id",
          label: "Business ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "name",
          label: "Business Name",
          field: "name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "time_created",
          label: "Date Partnered",
          field: row =>
            row.time_created ? moment.utc(row.time_created).local() : "",
          format: val => (!val ? "-" : moment(val).format("ll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {
    businesses() {
      return this.loading ? [] : this.$store.state.businesses.list;
    }
  },
  mounted() {
    this.$store.dispatch("businesses/POPULATE_LIST").finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    });
  },
  methods: {
    promptDeleteBusiness(business) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: business.name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("businesses/POST_REQUEST", {
            endpoint: "delete",
            params: { id: business.id }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
